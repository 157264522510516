import { Context, styled, useTheme } from '@obvio/app'
import { Cart } from '@obvio/template'
import { ToastContainer } from '@obvio/ui'

import { StoreNav } from './StoreNav'
import { Footer } from '../Footer'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

export const StyledCart = styled(Cart)`
  div {
    color: ${(theme) => theme.colors.secondary};
    z-index: 100;
  }
  section {
    z-index: 101;
  }
`

const Layout = styled.div`
  --hover-color: ${(theme) => theme.colors.secondary};
  padding-top: var(--nav-height);
  max-width: 2460px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

type StoreLayoutProps = AllowUndefinedForOptional<{
  children: React.ReactNode
  className?: string
}>

export function StoreLayout({
  children,
  className,
}: StoreLayoutProps): ReactElement {
  const themeContext = useTheme()
  return (
    <>
      <Layout className={className}>
        <StoreNav />
        <Context name="ecommerce" render="div">
          {children}
          <Footer store />
          <StyledCart />
        </Context>
      </Layout>
      <ToastContainer theme={themeContext as any} />
    </>
  )
}
