import { Context, useIsClient, useRouter } from '@obvio/app'
import { CookiesConsentBasicModal, useUi } from '@obvio/template'
import Head from 'next/head'
import { LocalBusinessJsonLd } from 'next-seo'
import { createContext, useContext, useEffect } from 'react'

import { Footer } from './Footer'
import { InlineBookCalendar } from './InlineBookCalendar/InlineBookCalendar'
import { Nav } from './Nav/Nav'
import { StyledCart } from './Store/StoreLayout'
import { StoreNav } from './Store/StoreNav'
import { heroSectionStore } from '@/utils/heroSectionStore'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement, ReactNode } from 'react'

export type LayoutType = 'Sklep' | 'Główny' | 'Kontakt' | 'Informacje'

type LayoutProps = AllowUndefinedForOptional<{
  children: ReactNode
  type: LayoutType
  hideCalendar?: boolean
}>

const LayoutContext = createContext<LayoutType>('Główny')

export function useLayoutType(): LayoutType {
  return useContext(LayoutContext)
}

function LayoutContent({
  children,
  type,
  hideCalendar,
}: LayoutProps): ReactElement {
  const isClient = useIsClient()
  const [{ calendar, hero }] = heroSectionStore('calendar', 'hero')

  const isStore = type === 'Sklep'
  const isInfo = type === 'Informacje'
  return (
    <LayoutContext.Provider value={type}>
      {isStore ? <StoreNav /> : isInfo ? null : <Nav />}
      {children}
      {!isInfo && <Footer />}
      {hideCalendar
        ? null
        : calendar &&
          !hero &&
          type !== 'Sklep' && <InlineBookCalendar fixedBar />}
      {isStore && (
        <Context name="ecommerce" render="div">
          <StyledCart />
        </Context>
      )}
      {isClient ? <CookiesConsentBasicModal /> : null}
    </LayoutContext.Provider>
  )
}

const websiteLD = {
  __html: `{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Poziom 511",
      "description": "Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!"
  }
`,
}

export function Layout({
  children,
  hideCalendar,
  type,
}: LayoutProps): ReactElement {
  const { asPath } = useRouter()
  const [, dispatch] = useUi()

  useEffect(() => {
    dispatch('CLOSE_ALL_PANELS')
  }, [dispatch, asPath])

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={websiteLD}
          key="product-jsonld"
        />
      </Head>
      <LocalBusinessJsonLd
        address={{
          streetAddress: 'Bonerów 33',
          addressLocality: 'Podzamcze',
          addressRegion: 'Śląskie',
          postalCode: '42-440',
          addressCountry: 'PL',
        }}
        images={[
          'https://www.poziom511.pl/mini/phpThumb.php?src=/zdjecia/artykuly/_WDF1799_P511.jpg&w=1000&fltr[]=wmi|/grafiki.png',
        ]}
        priceRange="$$$"
        description="Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!"
        name="Poziom 511"
        type="Hotel"
        id="poziom511.pl"
        geo={{
          latitude: 50.450_771_3,
          longitude: 19.553_020_4,
        }}
        telephone="+48327462800"
        url="https://poziom511.pl"
      />
      <LayoutContent type={type} hideCalendar={hideCalendar}>
        {children}
      </LayoutContent>
    </>
  )
}
