import { css, Link, mobile, styled, useRouter } from '@obvio/app'
import { SvgHalfLogo, SvgLogoUEWhite, SvgShoppingBag } from '@obvio/svg'
import { useUi } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'
import { AnimatePresence, useAnimation } from 'framer-motion'
import { useCallback, useEffect } from 'react'

import { NavBar } from './components'
import { NavAbstract } from './NavAbstract'
import { NavButton } from './NavButton'
import { NavMenu } from './NavMenu'
import { useLayoutType } from '../Layout'
import { heroSectionStore } from '@/utils/heroSectionStore'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const BgBlock = styled.div<{ $forceBlock: boolean }>`
  ${(_, { $forceBlock }) =>
    $forceBlock &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: var(--nav-height);
      background-color: ${(theme) => theme.background};
      z-index: 10;
      border-bottom: 1px solid ${(theme) => theme.stroke};
    `}

  @media ${mobile} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background-color: ${(theme) => theme.background};
    z-index: 10;
    border-bottom: 1px solid ${(theme) => theme.stroke};
  }
`

const FloatingLink = styled(Link)<{ $toRight: boolean }>`
  position: fixed;
  bottom: 4.5rem;
  right: 0.5rem;
  z-index: 10;
  svg {
    fill: ${(theme) => theme.colors.additional[0]};
  }

  @media ${mobile} {
    overflow: hidden;
    bottom: unset;
    right: unset;
    top: 0.75rem;
    left: 45%;
    svg {
      width: 150px !important;
    }
    ${(_, { $toRight }) =>
      $toRight &&
      css`
        left: unset;
        right: 0.5rem;
      `}
  }
`

const CartButton = styled(Button)`
  margin: 0;
  padding: 0;
  min-width: unset;
  svg {
    fill: transparent !important;
    color: black;
  }
`

type NavProps = AllowUndefinedForOptional<{
  forceBlock?: boolean
  black?: boolean
  noAbstract?: boolean
}>

export function Nav({ black, noAbstract, forceBlock }: NavProps): ReactElement {
  const { pathname } = useRouter()
  const controls = useAnimation()
  const layoutType = useLayoutType()
  const [{ menuPanelOpen }, dispatch] = useUi('menuPanelOpen')
  const [, dispatchCart] = useUi('cartOpen')
  const [{ hero }] = heroSectionStore('hero')
  const togglePanel = () =>
    dispatch(menuPanelOpen ? 'CLOSE_MENU_PANEL' : 'OPEN_MENU_PANEL')

  const setAbsoluteTop = useCallback(
    () => window.scrollY === 0 && controls.set({ position: 'absolute' }),
    [controls],
  )

  useEffect(() => {
    document.addEventListener('scroll', setAbsoluteTop)
    return () => document.removeEventListener('scroll', setAbsoluteTop)
  }, [setAbsoluteTop])

  return (
    <>
      <NavBar
        $black={Boolean(black)}
        $opened={menuPanelOpen}
        $layoutType={layoutType}
      >
        <Stack>
          <Stack spacing="0.1rem">
            <NavButton onClick={togglePanel} open={menuPanelOpen} />
            {pathname.includes('sklep') ? (
              <CartButton
                kind="transparent"
                onClick={() => dispatchCart('OPEN_CART')}
              >
                <SvgShoppingBag />
              </CartButton>
            ) : null}
          </Stack>
          <Link
            href="/"
            aria-label="Strona główna"
            onClick={() => dispatch('CLOSE_ALL_PANELS')}
          >
            <SvgHalfLogo />
          </Link>
        </Stack>
      </NavBar>
      {(!hero || forceBlock) && <BgBlock $forceBlock={Boolean(forceBlock)} />}
      <AnimatePresence>
        <NavMenu />
      </AnimatePresence>
      {!noAbstract && <NavAbstract />}

      <FloatingLink $toRight={Boolean(noAbstract)} href="/#ue">
        <SvgLogoUEWhite width={200} />
      </FloatingLink>
    </>
  )
}
