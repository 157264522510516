import {
  AssetsProvider,
  Image,
  mobile,
  plainRichText,
  notMobile,
  styled,
  Text,
  useQuery,
  useRemToPx,
  useRouter,
  useTranslation,
} from '@obvio/app'
import { Button, Loading, Stack } from '@obvio/ui'
import { useCallback } from 'react'

import { CarouselBase, CarouselSection } from '../Carousels/CarouselBase'
import { ReadMoreLink } from '@/components/ReadMoreLink'
import { QUERIES } from '@/constants/queries'

import type { ImageAsset } from '@obvio/app'
import type { AllowNullishForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const EventWrap = styled(Stack)`
  width: 25.875rem;
  height: 100%;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;
  @media ${mobile} {
    width: 100%;
  }
`
const EventImage = styled.div`
  width: 100%;
  height: 33.75rem;
  position: relative;

  @media ${mobile} {
    height: 10rem;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(theme) => theme.stroke};
`

const DateWrap = styled.span`
  font-size: 3rem;
  font-weight: 200;
  > sup {
    font-size: 1rem;
    top: -1.25em;
  }
`

type EventDateProps = {
  date: Date
}

function EventDate({ date }: EventDateProps) {
  const day = new Date(date).getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  return (
    <DateWrap>
      {day}
      <sup>{month}</sup>
    </DateWrap>
  )
}

export type Event = AllowNullishForOptional<{
  id: string
  date: Date
  title: string
  description: string
  category: {
    id: string
    title: string
  }
  startTime?: string | undefined
  slug: string
  images: ImageAsset[]
}>

const EventTitle = styled(Text)`
  font-size: 1.25rem;
  line-height: 100%;
  font-family: ${(theme) => theme.font};
`

const Description = styled(Text)`
  font-size: 1rem;
`

const EventButton = styled(Button)`
  all: unset;
`

type EventProps = {
  event: Event
  kind: EventsProps['kind']
}

const InfoWrap = styled(Stack)`
  margin-top: ${(theme) => theme.spacing.medium} !important;
`

function EventComp({ event, kind }: EventProps): ReactElement {
  const { setParam } = useRouter()

  const inHeader = kind === 'header'
  const [image] = event.images
  return (
    <EventWrap kind="vertical">
      <EventImage>
        <AssetsProvider path={`/event/${event.id}`}>
          <Image img={image} />
        </AssetsProvider>
      </EventImage>
      {inHeader ? null : <EventDate date={event.date} />}
      <Divider />
      <EventButton onClick={() => setParam('id', event.slug)}>
        <InfoWrap kind="vertical" spacing="medium">
          <EventTitle tag="h3">{event.title}</EventTitle>
          <Description>
            {`${plainRichText(event.description).slice(0, 100)}...`}
          </Description>
        </InfoWrap>
      </EventButton>
    </EventWrap>
  )
}

const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const MaxStack = styled(Stack)`
  width: 100%;
`
const MoreEvents = styled(ReadMoreLink)`
  margin-left: auto !important;
  margin-right: auto !important;
`

type EventsProps = {
  kind?: 'header' | 'section'
}

export function Events({ kind = 'section' }: EventsProps): ReactElement {
  const { data } = useQuery('getEvents', {
    select: QUERIES.events.getEvents,
  })

  const remToPx = useRemToPx()

  const comp = useCallback(
    (event: Event) => <EventComp kind={kind} event={event} />,
    [kind],
  )

  const inHeader = kind === 'header'

  return data ? (
    <CarouselBase
      data={data}
      render={comp}
      plugins={[CarouselBase.plugins.imgAutoHeight]}
      options={{
        breakpoints: {
          [mobile]: {
            drag: true,
            loop: true,
            slides: {
              origin: 'auto',
              perView: 1,
              spacing: remToPx(2),
            },
          },
          [notMobile]: {
            drag: false,
            loop: data.length > (inHeader ? 2 : 3),
            slides: {
              origin: inHeader ? 'center' : 'auto',
              perView: inHeader ? 2 : data.length <= 3 ? data.length : 'auto',
              number: data.length,
              spacing: remToPx(2),
            },
          },
        },
      }}
    />
  ) : (
    <Loading />
  )
}

export function EventsSection(eventsProps: EventsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <CarouselSection>
      <MaxStack kind="vertical" spacing="extraLarge">
        <Events {...eventsProps} />
        <MoreEvents href="/atrakcje">
          {t('label.moreUpcomingEvents')}
        </MoreEvents>
      </MaxStack>
    </CarouselSection>
  )
}
