import { Context, useRouter } from '@obvio/app'

import { Nav } from '../Nav/Nav'

import type { ReactElement } from 'react'

export function StoreNav(): ReactElement {
  const { asPath } = useRouter()
  const isMain = asPath === '/sklep'
  return (
    <Context name="default" render="div">
      <Nav black={!isMain} noAbstract forceBlock={!isMain} />
    </Context>
  )
}
